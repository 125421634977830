.page-container {
  max-height: 100vh;
  min-height: 800px;
  max-width: 1260px;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  font-family: $ff-proxima;

  @include vp-1023 {
    min-height: 0;
    height: 100vh;
    justify-content: center;
    align-items: center;
  }

  @include vp-767 {
    min-height: 478px;
    flex-direction: column;
    justify-content: center;
    padding: 0 15px;
  }

  &__text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 19px;
    width: 100%;
    margin: 48px 0 0 0;

    @include vp-1023 {
      margin: 0;
    }

    @include vp-767 {
      padding: 16px 0;
      align-items: center;
    }
  }

  & h1 {
    text-align: center;
    font-weight: 700;
    font-size: 360px;
    line-height: 1;
    margin: 0;

    @include vp-1023 {
      font-size: 280px;
      line-height: 1;
    }

    @include vp-767 {
      font-size: 150px;
      line-height: 120px;
      margin: 0 0 180px;
    }
  }

  &__text-main {
    font-weight: 700;
    font-size: 20px;
    line-height: 40px;
    margin: 0;
    color: $color-default-white;

    @include vp-767 {
      font-size: 16px;
      line-height: 19px;
      text-align: center;
    }
  }

  &__text-help {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    margin: 0 0 14px;

    @include vp-767 {
      font-size: 16px;
      line-height: 22px;
      text-align: center;
    }
  }

  &__text {
    border-radius: 15px;
    padding: 44px 35px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 20px;
    background-color: $color-new-bg;

    @include vp-767 {
      align-items: center;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    background-color: $color-new-button;
    text-transform: uppercase;
    color: $color-new-bg;
    padding: 17px;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    border: none;
    cursor: pointer;

    @include vp-767 {
      width: 100%;
    }
  }
}
